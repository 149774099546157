import '../../styles/pages/fall-winter-2021/a-tale-of-two-olympic-victories.scss';

import React from "react";
import Layout from '../../components/layout';
import { Helmet } from "react-helmet"
import ScrollAnimation from 'react-animate-on-scroll';
import MoreStories from "../../components/fall-winter-2021-stories/more-stories"
import { Parallax, Background } from 'react-parallax';
import Link from "gatsby-plugin-transition-link/AniLink";
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';

import socialBanner from "../../images/fall-winter-2021/social-banners/a-tale-of-two-olympic-victories.jpg";

import heroBG from "../../images/fall-winter-2021/a-tale-of-two-olympic-victories/hero-bg.jpg";
import title from "../../images/fall-winter-2021/a-tale-of-two-olympic-victories/title-graphic.png";


import relatedStory from "../../images/fall-winter-2021/olympic-gold-medalist-education-degree/melissa-seidemann-pool.jpg"

import larnieBoquirenGilchrist from '../../images/fall-winter-2021/a-tale-of-two-olympic-victories/larnie-boquiren-gilchrist.jpg'
import jimmyStitzGoldTokyo from '../../images/fall-winter-2021/a-tale-of-two-olympic-victories/jimmy-stitz-gold-tokyo.jpg'
import larnieBoquiren from '../../images/fall-winter-2021/a-tale-of-two-olympic-victories/Larnie-Boquiren.jpg'

import womensVolleyballWithJimmyStitz from '../../images/fall-winter-2021/a-tale-of-two-olympic-victories/womens-volleyball-with-jimmy-stitz.jpg'
import womensWaterPoloGoldMedalGroup from '../../images/fall-winter-2021/a-tale-of-two-olympic-victories/womens-water-polo-gold-medal-group.jpg'



export default class Index extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    var pageTitle = 'A Tale of Two Olympic Victories ';
    var pageDesc = 'Titans reflect on their historic gold medal wins at the Tokyo Olympics.';
    var slug = 'a-tale-of-two-olympic-victories';


    var heroStyles = {
      backgroundColor: '#222',
      backgroundImage: `url(${heroBG})`,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center center',
      backgroundSize: 'cover',
      color: 'white',
      height: '100vh'
    }

    return (
      <Layout headerStyle="white" locationInfo={this.props.location} issue="fall-winter-2021">
        <Helmet>
          <title>{pageTitle}</title>
          <body className={slug} />

          <meta name="description" content={pageDesc} />

          {/* Schema.org markup for Google+ */}
          <meta itemprop="name" content={pageTitle} />
          <meta itemprop="description" content={pageDesc} />
          <meta itemprop="image" content={socialBanner} />

          {/* Twitter Card data */}
          <meta name="twitter:title" content={pageTitle} />
          <meta name="twitter:description" content={pageDesc} />
          <meta name="twitter:image:src" content={socialBanner} />

          {/* Open Graph data */}
          <meta property="og:title" content={pageTitle} />
          <meta property="og:image" content={socialBanner} />
          <meta property="og:image:alt" content={pageTitle} />
          <meta property="og:description" content={pageDesc} />
        </Helmet>
        <div className="hero story animated fadeIn" style={heroStyles}>
          <div className="text">
            {/* <h1 className="animated fadeInDown delay-1s">{pageTitle}</h1> */}
            <h1 className="animated zoomIn delay-1s"><img src={title} alt={pageTitle} id="title-graphic" /></h1>
            <p className="sub-heading animated fadeInDown delay-1s">Titans reflect on their historic gold medal wins at the Tokyo Olympics.</p>
            <span className="animated fadeInDown delay-1s byline">By Lynn Juliano, Photos courtesy of Jeff Cable, USA Water Polo, Kaleigh Gilchrist, Larnie Boquiren and Jimmy Stitz</span>
          </div>
        </div>
        <article className="wrap small">

          <ScrollAnimation animateOnce={true} animateIn="fadeIn">
          <BrowserView>
          <aside className="related-stories align-outside">
            <h2>Related Story</h2>
            <Link
            className="related-link"
            cover
            bg="#FF7900"
            direction="up"
            duration={1}
            duration={.75}
            to="/fall-winter-2021/olympic-gold-medalist-education-degree/" >
              <img src={relatedStory} alt="" className="link-image" />
              <span className="link-text">Next Goal for Olympic Gold Medalist Is an Education Degree</span>
            </Link>
          </aside>
          </BrowserView>
          
          <p><span className="intro-text">Olympian Kaleigh Gilchrist made headlines this summer</span> during the Tokyo Games for her gold medal win as part of USA Water Polo, battling back from a 2019 accident in South Korea when a nightclub balcony crumbled beneath her. </p>

          <p>Gilchrist suffered lacerations and muscle damage during the collapse, which killed two and injured 16.</p>

          <p>In a thank you letter to <a href="https://www.teamusa.org/News/2021/July/19/Kaleigh-Gilchrist-Thanks-Trainer-Larnie-Boquiren-Reflecting-On-A-Day-Shell-Never-Forget" target="_blank" rel="noreferrer">Larnie Boquiren</a>, a Cal State Fullerton alumna and sports medicine manager for the USA Water Polo Women’s National Team, Gilchrist shares her long road to recovery and the summer games:</p>
          </ScrollAnimation>

          <ScrollAnimation animateOnce={true} animateIn="fadeIn">
          <figure className="align-left large align-outside">
            <img src={larnieBoquirenGilchrist} alt="Larnie Boquiren with an injured Kaleigh Gilchrist" />
            <figcaption>Sport medicine manager Larnie Boquiren, water polo players Maddie Musselman and Kaleigh Gilchrist, and strength and conditioning coach Tim Pelot</figcaption>
          </figure>
          </ScrollAnimation>
          
          <ScrollAnimation animateOnce={true} animateIn="fadeIn">
          <p>“Dear Larnie, I am not quite sure I would be able to call myself a two-time Olympian without your love, support and guidance,” writes Gilchrist. “You were there with me going into surgery and the first person I remember when I woke up. You gave me some of the greatest news I’ve ever received: ‘Surgery went well and you were millimeters away from your nerve.’ It took me a while to truly comprehend what that meant. I was one of the lucky ones that evening.”</p>

          <p>The next five months, Boquiren and Gilchrist focused on rehabilitation.</p> 

          <p>“The days were long and the work was hard,” recalls Gilchrist. “Progress was being made and fun was being had even through some of my darkest days. This experience taught me a lot about perspective, but so did you. You went above and beyond your duty as a trainer, being a great friend, being supportive, and giving me the foundation to build back my body and my career.”</p>
          </ScrollAnimation>

          <ScrollAnimation animateOnce={true} animateIn="fadeIn">
          <figure className="align-right large align-outside">
            <img src={larnieBoquiren} alt="Larnie Boquiren" />
            <figcaption>Larnie Boquiren ’04 (B.S. kinesiology)</figcaption>
          </figure>
          </ScrollAnimation>

          <ScrollAnimation animateOnce={true} animateIn="fadeIn">
          <p>As sports medicine manager for the team, Boquiren is responsible for evaluating injuries, designing and implementing treatment and rehabilitation plans, and providing injury prevention and recovery methods. In addition to maintaining the athletes’ overall care during the Tokyo Games, Boquiren stepped up as a major support system for the players while friends, family and fans were unable to attend the games due to the COVID-19 pandemic.</p>
          </ScrollAnimation>

        </article>

        <ScrollAnimation animateOnce={true} animateIn="fadeIn">
          <Parallax
          bgImage={womensWaterPoloGoldMedalGroup}
          bgImageAlt=""
          strength={200}
          className="full-width">
            <div className="caption">
            USA Water Polo Women’s National Team at the Tokyo Olympics
            </div>
            <div className="height" />
          </Parallax>
        </ScrollAnimation>

        <article className="wrap small">

          <ScrollAnimation animateOnce={true} animateIn="fadeIn">
          <p>USA Water Polo’s win over Spain secured the third-straight Olympic victory for the women’s team (2012 London, 2016 Rio, 2020 Tokyo) and the second for both Gilchrist and Boquiren. The team set several other Olympic records in Tokyo, including the largest margin of victory in a women’s water polo final (nine goals), most goals scored during one Olympic Games (109), most goals scored of all time at the Olympic Games (366) and most wins of all time (27).</p>

          <p>Boquiren is just one example of several Cal State Fullerton kinesiology graduates who have played critical roles in <a href="http://news.fullerton.edu/2021/07/titan-athletic-trainers-join-olympians-in-tokyo/" target="_blank" rel="noreferrer">supporting international Olympians</a> through the years.</p>
          </ScrollAnimation>

          <ScrollAnimation animateOnce={true} animateIn="fadeIn">
          <h2>Last Chance for Gold</h2>
          <p>For Jimmy Stitz, sports physiologist for the USA Volleyball Women’s National Team, Tokyo 2020 was his last chance to be a part of a gold medal-winning Olympic team. This fall, Stitz moves on from his role with Team USA to serve as director of strength and conditioning at Utah State University.</p>

          <p>The pandemic presented a number of training challenges, according to Stitz, including naming an Olympic team without competing in over a year and a half. “We had nine matches to trim our roster down from 23 to 12 athletes.”</p>
          </ScrollAnimation>

          <ScrollAnimation animateOnce={true} animateIn="fadeIn">
          <figure className="align-wide">
            <img src={jimmyStitzGoldTokyo} alt="Jimmy Stitz" />
            <figcaption>Jimmy Stitz ’13 (M.S. kinesiology)</figcaption>
          </figure>
          </ScrollAnimation>

          <ScrollAnimation animateOnce={true} animateIn="fadeIn">
          <p>In Tokyo, the Cal State Fullerton alumnus was responsible for “all things strength and conditioning” as well as travel logistics and training schedules.</p>

          <p>Stitz, who traveled with the bronze medal-winning team to Rio in 2016, says differences were notable between the two games.</p>

          <p>“A lot of the pomp and circumstance of the games was just not present in Tokyo like it was in Rio,” says Stitz. “A sad reality of the last year and a half is that athletes and teams have become used to competing in venues without spectators.”</p>
          </ScrollAnimation>

          <ScrollAnimation animateOnce={true} animateIn="fadeIn">
          <p>To prepare, both Stitz and Boquiren say their teams learned how to channel their own energy going into matches.</p>

          <p>“While our athletes get energy from having family and friends in the stands, we kept in perspective that our families were going to be safest and healthiest at home,” explains Boquiren. “During the tournament, that meant all team members were each other’s biggest cheerleaders and support.”</p>

          <p>After a yearlong postponement of the Tokyo Games, the USA Volleyball women’s team made history with its first-ever Olympic gold medal over Brazil.</p>
          </ScrollAnimation>

        </article>

        <ScrollAnimation animateOnce={true} animateIn="fadeIn">
          <Parallax
          bgImage={womensVolleyballWithJimmyStitz}
          bgImageAlt=""
          strength={100}
          className="full-width">
            <div className="caption">
            USA Volleyball Women’s National Team at the Tokyo Olympics
            </div>
            <div className="height" />
          </Parallax>
        </ScrollAnimation>

        <article className="wrap small">

        <ScrollAnimation animateOnce={true} animateIn="fadeIn">
          <p>“Winning a gold medal is the most difficult thing I have ever been a part of,” says Stitz. “You can do everything right and still not achieve the ultimate goal. To win this medal after many years of pouring ourselves into this pursuit has me feeling joy, gratitude and validation.”</p>

          <h2>Producing Top-Tier Athletic Trainers</h2>
          <p>After earning a bachelor’s degree from Cal State Fullerton, Boquiren ’04 (B.S. kinesiology) completed a master’s degree in sports health care from the Arizona School of Health Sciences at A.T. Still University.</p>

          <p>Thankful for her CSUF education, Boquiren still remembers the strong examples set by her faculty mentors Julie Max (coach emeritus of athletics) and Robert Kersey (professor emeritus of kinesiology).</p>
          </ScrollAnimation>

          <ScrollAnimation animateOnce={true} animateIn="fadeIn">
          <p>“In moments of hard conversations, and differences in thoughts and opinions, these mentors serve as my reminder to do the right thing even if it’s unpopular,” shares Boquiren. </p>

          <p>From the Los Angeles Lakers to international Olympic teams, alumni of Cal State Fullerton’s kinesiology and athletic training programs have gone to work with scores of professional, amateur, collegiate and K-12 teams.</p>

          <p>“Something I truly began to learn while earning my master’s degree at CSUF was that it takes a village to achieve great things,” says Stitz ’13 (M.S. kinesiology). “That degree helped me get a job that allowed me to win an Olympic gold medal. One does not come without the other.”</p>
          </ScrollAnimation>

          <ScrollAnimation animateOnce={true} animateIn="fadeIn">
          <p>For Boquiren, the period after the games is a time of rest, reflection and re-evaluation. </p>

          <p>“I get overwhelmed with emotion when I think about all the pieces that led to that win,” she says. “The physical and mental preparation, hard conversations, execution of protocols and safety measures, building and rebuilding of relationships — all of these pieces came together in the end.” <span className="end-mark" /></p>
          </ScrollAnimation>

          <ScrollAnimation animateOnce={true} animateIn="fadeIn">
          <p className="learn-more">Support the College of Health and Human Development<br /><a href="https://campaign.fullerton.edu/hhd" target="_blank" rel="noreferrer">campaign.fullerton.edu/hhd</a></p>
          </ScrollAnimation>

          <MobileView>
          <ScrollAnimation animateOnce={true} animateIn="fadeIn">
          <aside className="related-stories align-outside">
            <h2>Related Story</h2>
            <Link
            className="related-link"
            cover
            bg="#FF7900"
            direction="up"
            duration={1}
            duration={.75}
            to="/fall-winter-2021/olympic-gold-medalist-education-degree/" >
              <img src={relatedStory} alt="" className="link-image" />
              <span className="link-text">Next Goal for Olympic Gold Medalist Is an Education Degree</span>
            </Link>
          </aside>
          </ScrollAnimation>
          </MobileView>
        
        </article>
        <MoreStories slug={slug} />
      </Layout>
    );
  }
} 